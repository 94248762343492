html {
    height: 100%;
}

body {
    background: rgb(246 248 252);
    margin: 0;
    font-family: "Roboto", serif;
    height: 100%;
}

#root {
    height: 100%;
}

.css-1gsv261 {
    border-bottom: none;
}